"use client";

import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@kamio/design-system/components/ui/pagination";
import { usePathname, useSearchParams } from "next/navigation";

interface Props {
  prefix?: string;
  page: number;
  perPage?: number;
  total: number;
  pageAdjustment?: number;
}

export function Paging({ page, total, perPage = 10, prefix, pageAdjustment = 0 }: Props) {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const createPageURL = (pageNumber: number | string) => {
    const params = new URLSearchParams(searchParams);
    params.set(`${prefix ?? ""}page`, pageNumber.toString());
    return `${pathname}?${params.toString()}`;
  };

  const pages = Array.from({ length: Math.ceil(total / perPage) }, (_, i) => i + 1);

  if (pages.length <= 1) {
    return null;
  }

  return (
    <Pagination>
      <PaginationContent>
        {page > 1 && (
          <PaginationItem>
            <PaginationPrevious href={createPageURL(page - 1)} />
          </PaginationItem>
        )}
        {pages.map((pageNumber) => {
          return (
            <PaginationItem key={pageNumber}>
              <PaginationLink
                href={createPageURL(pageNumber + pageAdjustment)}
                isActive={page === pageNumber + pageAdjustment}
              >
                {pageNumber}
              </PaginationLink>
            </PaginationItem>
          );
        })}

        {/* <PaginationItem>
          <PaginationEllipsis />
        </PaginationItem> */}

        {page < pages.length && (
          <PaginationItem>
            <PaginationNext href={createPageURL(page + 1)} />
          </PaginationItem>
        )}
      </PaginationContent>
    </Pagination>
  );
}
